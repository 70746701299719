.header {
  background: url("./main-image.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.header__blur {
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
}

.header__content-container {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.header__nav-list {
  list-style-type: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 2rem 0 4rem 0;
}

.header__nav-list li:first-child {
  width: 100%;
  text-align: center;
}

.header__nav-list li a {
  color: var(--white);
  text-decoration: none;
  font-family: var(--roboto);
  font-weight: bold;
}

.header__nav-list li:first-child a {
  font-family: var(--mono);
  font-weight: bold;
}

.header__nav-list li a:hover {
  color: var(--dark);
}
.header__nav-list .header__some-icon {
  color: var(--yellow);
  font-size: 1.5rem;
}
.header__nav-list .header__some-icon:hover {
  color: var(--dark);
}

.header__nav-list .header__cv {
  background-color: var(--yellow);
  padding: 0.25rem 1rem;
  border-radius: 4px;
  color: var(--dark);
  font-weight: bold;
}

.header__nav-list .header__cv:hover {
  color: var(--yellow);
  background-color: var(--dark);
}

.header__intro {
  color: var(--white);
  font-family: var(--mono);
  font-weight: bold;
  padding: 1rem 0;
}
.header__name {
  font-size: 4rem;
  color: var(--yellow);
  font-family: var(--roboto);
  font-weight: bold;
}

.header__occupation {
  font-size: 2rem;
  word-break: break-all;
  color: var(--white);
  font-family: var(--roboto);
  font-weight: bold;
}

.header__intro-text {
  color: var(--white);
  padding: 1rem;
  margin: 1rem 0;
  line-height: 2;
  font-family: var(--roboto);
  font-weight: bold;
  border: 1px solid var(--white);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  width: fit-content;
}

.header__intro-text a {
  color: var(--yellow);
}

@media (min-width: 750px) {
  .header__nav-list {
    justify-content: flex-end;
    margin: 0 auto;
  }
  .header__nav-list li:first-child {
    width: auto;
    flex: 1;
    text-align: start;
  }
  .header__occupation {
    font-size: 4rem;
  }
}
